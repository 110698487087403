import times from "lodash/times";
import { Fragment } from "react";

import { Container } from "./components/Container";

import { cn } from "@/styles/lib";

type ShoppingBagSkeletonProps = {
  withDelivery?: boolean;
  withDiscount?: boolean;
  withHeader?: boolean;
  withSubtotal?: boolean;
  withTotal?: boolean;
};

export const ShoppingBagSkeleton = ({
  withDelivery = true,
  withHeader = true,
  withTotal = true,
  withSubtotal = true,
  withDiscount = true,
}: ShoppingBagSkeletonProps) => (
  <Container>
    {withHeader && <div className="skeleton h-9 w-40" />}

    <hr />
    {times(2, i => (
      <Fragment key={i}>
        <div className="flex gap-[inherit]">
          <div className="skeleton h-[100px] w-[75px]" />

          <div className="flex flex-col justify-evenly">
            <div className="skeleton h-5 w-36" />
            <div className="skeleton h-5 w-32" />
            <div className="skeleton h-8 w-28" />
          </div>
        </div>
        <hr />
      </Fragment>
    ))}

    {[withDelivery, withTotal, withSubtotal, withDiscount]
      .filter(Boolean)
      .map((_, i, array) => (
        <div
          className={cn("flex justify-between [&>*]:h-6", {
            "[&>*]:h-[28px]": withTotal && i === array.length - 1,
          })}
          key={i}
        >
          <div className="skeleton w-36" />
          <div className="skeleton w-24" />
        </div>
      ))}
  </Container>
);
