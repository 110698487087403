import { tOrFallback } from "@projectluna/lib/next-intl/utils";

import { type GetTypeErrorCodeMessage } from "@/errorCodes/types";
import { type StripeError } from "@/lib/errors/types";

export const getStripeErrorCodeMessage: GetTypeErrorCodeMessage<
  StripeError
> = (t, { code }) => {
  switch (code) {
    case "incomplete_number":
    case "incorrect_number":
    case "incorrect_cvc":
      return t("errorCodes.stripe.invalidCardData");
  }

  return tOrFallback(
    t,
    `errorCodes.stripe.${code}`,
    "errorCodes.stripe.default"
  );
};
