import { useTranslations } from "next-intl";

import {
  ShoppingBagPrice,
  type ShoppingBagPriceProps,
} from "./ShoppingBagPrice";

export const TotalPrice = ({
  price,
}: Pick<ShoppingBagPriceProps, "price">) => {
  const t = useTranslations("common");

  return (
    <ShoppingBagPrice heading={t("total")} price={price} variant="primary" />
  );
};
