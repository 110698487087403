import { type WithChildren, type WithHTMLProp } from "@/lib/types";

import { Container } from "./components/Container";
import { Discount } from "./components/Discount";
import { Header } from "./components/Header";
import { Lines } from "./components/Lines";
import { ShippingPrice } from "./components/ShippingPrice";
import { SubtotalPrice } from "./components/SubtotalPrice";
import { TotalPrice } from "./components/TotalPrice";

export const ShoppingBag = ({
  children,
  className,
}: WithChildren & WithHTMLProp<"className">) => (
  <Container className={className}>{children}</Container>
);

ShoppingBag.Lines = Lines;
ShoppingBag.SubtotalPrice = SubtotalPrice;
ShoppingBag.ShippingPrice = ShippingPrice;
ShoppingBag.Discount = Discount;
ShoppingBag.TotalPrice = TotalPrice;
ShoppingBag.Header = Header;
