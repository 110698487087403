import { tOrFallback } from "@projectluna/lib/next-intl/utils";

import { type GetTypeErrorCodeMessage } from "@/errorCodes/types";
import { type CheckoutErrorFragment } from "@/graphql/fragments/generated";

export const getCheckoutErrorCodeMessage: GetTypeErrorCodeMessage<
  CheckoutErrorFragment
> = (t, { code, field }) => {
  switch (code) {
    case "INVALID":
      switch (field) {
        case "promoCode":
          return t("errorCodes.checkout.invalidPromoCode");

        case "phone":
          return t("errorCodes.checkout.invalidPhone");

        case "postalCode":
          return t("errorCodes.checkout.invalidPostalCode");
      }
  }

  return tOrFallback(
    t,
    `errorCodes.checkout.${code}`,
    "errorCodes.checkout.default"
  );
};
