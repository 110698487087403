import { type ReactNode } from "react";

import { Price, type PriceProps } from "@/components/Price";

import { cn } from "@/styles/lib";

type PriceVariant = "secondary" | "primary" | "discount";

export type ShoppingBagPriceProps = {
  heading: ReactNode;
  price: PriceProps["price"];
  variant?: PriceVariant;
};

export const ShoppingBagPrice = ({
  heading,
  price,
  variant = "secondary",
}: ShoppingBagPriceProps) => {
  const isDiscount = variant === "discount";

  return (
    <div
      className={cn(
        "text-default flex w-full justify-between",
        "items-center [&+&]:-mt-3 [&>*]:font-semibold [&>*]:text-gray-600",
        {
          "[&>*]:text-lg [&>*]:font-bold [&>*]:text-gray-700":
            variant === "primary",
          "[&>*]:font-semibold [&>*]:text-gold": isDiscount,
        }
      )}
    >
      <p>{heading}</p>

      <Price price={price} />
    </div>
  );
};
