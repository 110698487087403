import * as Sentry from "@sentry/nextjs";

import { COMMON_CLIENT_CONFIG } from "#config/common/client";

type ExceptionContext = Parameters<typeof Sentry.captureException>[1];

export const captureError = (error: Error, context?: ExceptionContext) => {
  const errorId = Sentry.captureException(error, context);

  if (COMMON_CLIENT_CONFIG.IS_DEVELOPMENT) {
    console.error(error, context);
  } else {
    console.error(`Sentry error ID: ${errorId}`);
    console.error(error);
  }

  return errorId;
};

type MessageContext = Parameters<typeof Sentry.captureMessage>[1];

export const captureMessage = (error: string, context?: MessageContext) => {
  const errorId = Sentry.captureMessage(error, context);

  console.error(`Sentry message ID: ${errorId}`);
  console.error(error);

  return errorId;
};

export const getLastEventId = () => Sentry.lastEventId();
