import { useTranslations } from "next-intl";

import { type WithChildren } from "@/lib/types";

export const Header = ({
  children,
  header,
}: WithChildren<false> & { header?: string }) => {
  const t = useTranslations();

  return (
    <div className="flex items-center justify-between gap-4">
      <h4 className="text-2xl font-semibold uppercase">
        {header || t("checkout.cart")}
      </h4>

      {children}
    </div>
  );
};
