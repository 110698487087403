"use client";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";

import {
  Accordion,
  AccordionContent,
  AccordionIndicator,
  AccordionTrigger,
} from "@/components/Accordion";
import { Button } from "@/components/Button";
import { Icon } from "@/components/Icon";
import { Input } from "@/components/Input";
import { Spinner } from "@/components/Spinner";
import { hasDiscount } from "@/lib/discount/helpers";
import type { Discount as DiscountType } from "@/lib/discount/types";
import type { Maybe } from "@/lib/types";

import { ShoppingBagPrice } from "./ShoppingBagPrice";

import { cn } from "@/styles/lib";

type DiscountProps = {
  discount: Maybe<DiscountType>;
  isDisabled?: boolean;
  isProcessing?: boolean;
  onDiscountAdd?: (code: string) => Promise<void>;
  onDiscountRemove?: (code: string) => Promise<void>;
};

export const Discount = ({
  discount,
  onDiscountRemove,
  onDiscountAdd,
  isDisabled,
  isProcessing,
}: DiscountProps) => {
  const t = useTranslations();
  const [code, setCode] = useState("");

  const canAddDiscount = !hasDiscount(discount) && onDiscountAdd;
  const canRemoveDiscount = onDiscountRemove;

  useEffect(() => {
    setCode("");
  }, [canAddDiscount]);

  if (canAddDiscount) {
    return (
      <>
        <div className="py-1">
          <Accordion>
            <AccordionTrigger>
              {t("checkout.havePromoCode")}
              <AccordionIndicator />
            </AccordionTrigger>

            <AccordionContent>
              <div className="inline-flex w-full gap-2 pt-2">
                <Input
                  className="mb-0"
                  disabled={isDisabled || isProcessing}
                  inputClassName="py-2"
                  name="discount"
                  value={code}
                  onChange={evt => setCode(evt.target.value.trim())}
                />
                <Button
                  color="gold"
                  disabled={isDisabled}
                  isProcessing={isProcessing}
                  size="sm"
                  onClick={() => {
                    const discountCode = code.trim();
                    discountCode && onDiscountAdd(code.trim());
                  }}
                >
                  {t("common.activate")}
                </Button>
              </div>
            </AccordionContent>
          </Accordion>
        </div>
        <hr />
      </>
    );
  }

  if (!hasDiscount(discount)) {
    return null;
  }

  return (
    <>
      <div className="flex items-center gap-2">
        {canRemoveDiscount &&
          (isProcessing ? (
            <div className="p-[10px]">
              <Spinner size={20} />
            </div>
          ) : (
            <Icon
              className={cn({ "pointer-events-none": isDisabled })}
              isProcessing={isProcessing}
              onClick={() =>
                discount?.code && onDiscountRemove?.(discount.code)
              }
            >
              <XMarkIcon />
            </Icon>
          ))}

        <ShoppingBagPrice
          heading={discount.code}
          price={
            discount.discount && {
              ...discount.discount,
              amount: discount.discount?.amount * -1,
            }
          }
          variant="discount"
        />
      </div>
      <hr />
    </>
  );
};
