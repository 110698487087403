import { useTranslations } from "next-intl";
import { type SnackbarKey, useSnackbar, type VariantType } from "notistack";
import { useState } from "react";

import { captureError } from "@projectluna/lib/errors";

import { mapApiErrorCode } from "@/errorCodes/helpers";
import {
  type ApiError,
  type ApiErrorType,
  type ApiErrorWithType,
} from "@/lib/errors/types";

export type ApiErrorHandler = (
  errors: Array<ApiError | ApiErrorWithType>,
  errorType?: ApiErrorType
) => Promise<void> | void;

type UseServerErrorsOpts = {
  errorType?: ApiErrorType;
  snackPersist?: boolean;
  snackVariant?: VariantType;
};

export const useServerErrors = (opts?: UseServerErrorsOpts) => {
  const t = useTranslations();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [errorIds, setErrorIds] = useState<SnackbarKey[]>([]);

  const {
    errorType: globalErrorType,
    snackVariant,
    snackPersist,
  }: UseServerErrorsOpts = {
    snackVariant: "error",
    snackPersist: false,
    ...opts,
  };

  const clearApiErrors = async () => {
    for (const snackbarId of errorIds) {
      closeSnackbar(snackbarId);
      /**
       * Allow snackbar to disappear from the screen for better UX, and always
       * having a `fresh` one.
       */
      await new Promise(resolve =>
        setTimeout(() => {
          setErrorIds(ids => ids.filter(id => id !== snackbarId));
          resolve(snackbarId);
        }, 300)
      );
    }
  };

  const apiErrorsHandler: ApiErrorHandler = (errors, handlerErrorType?) => {
    errors.map(error => {
      let message: string;
      const errorType =
        (error as ApiErrorWithType)?.errorType ||
        handlerErrorType ||
        globalErrorType;

      if (!errorType) {
        captureError(
          new Error("No error type passed to `apiErrorsHandler`."),
          {
            extra: { error, opts },
          }
        );
        message = t("error.unexpectedErrorOccurred");
      } else {
        message = mapApiErrorCode({ t, type: errorType, error });
      }

      const snackbarId = enqueueSnackbar(message, {
        variant: snackVariant,
        persist: snackPersist,
      });

      setErrorIds(ids => [...ids, snackbarId]);
    });
  };

  return { clearApiErrors, apiErrorsHandler };
};
