import { useTranslations } from "next-intl";

import {
  ShoppingBagPrice,
  type ShoppingBagPriceProps,
} from "./ShoppingBagPrice";

export const SubtotalPrice = ({
  price,
}: Pick<ShoppingBagPriceProps, "price">) => {
  const t = useTranslations("common");
  return (
    <ShoppingBagPrice
      heading={t("subtotal")}
      price={price}
      variant="secondary"
    />
  );
};
