import { type WithChildren, type WithHTMLProp } from "@/lib/types";

import { cn } from "@/styles/lib";

export const Container = ({
  children,
  className,
}: WithChildren & WithHTMLProp<"className">) => (
  <div className={cn("flex w-full flex-col gap-4", className)}>{children}</div>
);
