import {
  type GetTranslations,
  type TranslationMessage,
  type TranslationNamespace,
} from "./types";

export const tOrFallback = <Namespace extends TranslationNamespace = never>(
  t: GetTranslations<Namespace>,
  key: TranslationMessage<Namespace> & any,
  fallbackKey: TranslationMessage<Namespace>
) => {
  const translatedMessage = t(key);
  /**
   * EndsWith should succeed with check event if `t` was initiated with a namespace.
   */
  return translatedMessage.endsWith(key) ? t(fallbackKey) : translatedMessage;
};
