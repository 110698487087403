import range from "lodash/range";
import { type ComponentProps } from "react";

import { isNotNil } from "@/lib/core";

import { cn } from "@/styles/lib";

export type QuantityAction = "change" | "increase" | "decrease";

export type CartQuantityInputProps = {
  disabled?: boolean;
  maxQuantity?: number;
  name: string;
  onQuantityChange(
    quantity: number,
    action: "change" | "increase" | "decrease"
  ): Promise<void> | void;
  quantity: number;
};

const QuantityButton = (props: ComponentProps<"button">) => (
  <button
    className="inline-flex items-center p-2.5 text-[20px] disabled:text-gray-400"
    {...props}
  />
);

export const CartQuantityInput = ({
  maxQuantity = 10,
  disabled,
  quantity,
  name,
  onQuantityChange,
}: CartQuantityInputProps) => {
  const increaseQty = async () => setQty(quantity + 1, "increase");

  const decreaseQty = async () => setQty(quantity - 1, "decrease");

  const setQty = async (qty: number, action: QuantityAction) => {
    if (qty < 0 || (isNotNil(maxQuantity) && qty > maxQuantity)) {
      return;
    }
    await onQuantityChange(qty, action);
  };

  return (
    <div>
      <span className="inline-flex h-[35px] rounded-md bg-gray-200 font-semibold">
        <QuantityButton
          disabled={disabled || quantity === 0}
          onClick={decreaseQty}
        >
          -
        </QuantityButton>
        <select
          className={cn(
            "appearance-none",
            "bg-inherit",
            "bg-none",
            "border-0",
            "enabled:cursor-pointer",
            "focus:border-transparent",
            "focus:ring-0",
            "min-w-[50px]",
            "p-0",
            "disabled:text-gray-400",
            "text-center",
            "text-align-last-center"
          )}
          disabled={disabled || maxQuantity === 1}
          name={name}
          value={quantity}
          onChange={evt => setQty(parseInt(evt.target.value), "change")}
        >
          {range(1, maxQuantity + 1).map(i => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </select>
        <QuantityButton
          disabled={
            disabled || (isNotNil(maxQuantity) && maxQuantity <= quantity)
          }
          onClick={increaseQty}
        >
          +
        </QuantityButton>
      </span>
    </div>
  );
};
