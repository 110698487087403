"use client";

import { TrashIcon } from "@heroicons/react/24/outline";
import { useTranslations } from "next-intl";

import {
  CartQuantityInput,
  type QuantityAction,
} from "@/components/CartQuantityInput";
import { Icon } from "@/components/Icon";
import { ImagePlaceholder } from "@/components/ImagePlaceholder";
import { Link } from "@/components/Link";
import { NextImage } from "@/components/NextImage";
import { Spinner } from "@/components/Spinner";
import { TaxedPrice } from "@/components/TaxedPrice";
import type { CheckoutLineFragment } from "@/graphql/fragments/generated";
import { useLocalizedPaths } from "@/lib/paths/client";
import {
  getProductName,
  getVariantMaxQuantity,
  variantHash,
} from "@/lib/product/helpers";
import { type WithHTMLProp } from "@/lib/types";

import { cn, lineSizes } from "@/styles/lib";

export type LineProps = WithHTMLProp<"className"> & {
  isLineProcessing?: boolean;
  isProcessing?: boolean;
  line: CheckoutLineFragment;
  onLineDelete?(lineId: string): Promise<void>;
  onLineQuantityChange?(
    lineId: string,
    quantity: number,
    action: QuantityAction
  ): Promise<void>;
};

export const Line = ({
  isProcessing,
  isLineProcessing,
  className,
  onLineQuantityChange,
  onLineDelete,
  line: {
    undiscountedTotalPrice,
    variant: { media, product, id: variantId },
    variant,
    totalPrice,
    quantity,
    id: lineId,
  },
}: LineProps) => {
  const t = useTranslations();
  const paths = useLocalizedPaths();

  const image = media?.[0] || product?.thumbnail;
  const href = paths.product.asPath({
    slug: product.slug,
    hash: variantHash(variant),
  });
  const name = getProductName(product, variant);
  const maxQuantity = getVariantMaxQuantity(variant);

  const handleLineQuantityChange = async (
    quantity: number,
    action: QuantityAction
  ) => {
    await onLineQuantityChange?.(lineId, quantity, action);
  };

  const handleLineDelete = async () => {
    await onLineDelete?.(lineId);
  };

  return (
    <div className={cn("flex w-full", className)}>
      <div className="flex basis-full justify-between">
        <div className="flex gap-4">
          <Link className="relative h-[100px] w-[75px]" href={href}>
            {image?.thumbnail ? (
              <NextImage
                alt={image.alt || name}
                className="h-full w-full object-cover"
                height={0}
                sizes={lineSizes}
                src={image.thumbnail}
                width={0}
              />
            ) : (
              <ImagePlaceholder className="h-[100px] w-[75px]" />
            )}
          </Link>

          <div className="flex flex-1 flex-col justify-evenly">
            <Link className="relative" href={href}>
              {name}
            </Link>

            <TaxedPrice
              price={totalPrice}
              priceUndiscounted={{
                net: undiscountedTotalPrice,
                gross: undiscountedTotalPrice,
              }}
            />
            {onLineQuantityChange ? (
              <CartQuantityInput
                disabled={isProcessing || isLineProcessing}
                maxQuantity={maxQuantity}
                name={lineId}
                quantity={quantity}
                onQuantityChange={handleLineQuantityChange}
              />
            ) : (
              <p>
                {quantity} {t("product.qty")}
              </p>
            )}
          </div>
        </div>

        <span>
          {onLineDelete &&
            (isLineProcessing ? (
              <Spinner size={40} />
            ) : (
              <Icon isProcessing={isProcessing} onClick={handleLineDelete}>
                <TrashIcon />
              </Icon>
            ))}
        </span>
      </div>
    </div>
  );
};
