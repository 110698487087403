import { Fragment } from "react";

import { Line, type LineProps } from "./Line";

type LinesProps = {
  lines: LineProps["line"][];
  processingLine?: string | null;
} & Omit<LineProps, "line" | "isLineProcessing">;

export const Lines = ({ lines, processingLine, ...props }: LinesProps) => (
  <>
    <hr />
    {lines.map(line => (
      <Fragment key={line.id}>
        <Line
          isLineProcessing={processingLine === line.id}
          line={line}
          {...props}
        />
        <hr />
      </Fragment>
    ))}
  </>
);
