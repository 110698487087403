import { type GetTranslations } from "@projectluna/lib/next-intl/types";

import {
  type CheckoutErrorFragment,
  type PaymentErrorFragment,
} from "@/graphql/fragments/generated";
import { type StripeError } from "@/lib/errors/types";
import { type ApiErrorType } from "@/lib/types";

import { getCheckoutErrorCodeMessage } from "./checkoutErrorCodes";
import { getPaymentErrorCodeMessage } from "./paymentErrorCodes";
import { getStripeErrorCodeMessage } from "./stripeErrorCodes";
import { type FormattedError, type IncomingFormattedError } from "./types";

export const mapApiErrorCode = ({
  error,
  type,
  t,
}: {
  error: IncomingFormattedError;
  t: GetTranslations;
  type: ApiErrorType;
}) => {
  let mappedMessage;

  switch (type) {
    case "checkout":
      mappedMessage = getCheckoutErrorCodeMessage(
        t,
        error as CheckoutErrorFragment
      );
      break;

    case "stripe":
      mappedMessage = getStripeErrorCodeMessage(
        t,
        error as FormattedError<StripeError>
      );
      break;

    case "payment":
      mappedMessage = getPaymentErrorCodeMessage(
        t,
        error as FormattedError<PaymentErrorFragment>
      );
      break;
  }

  return mappedMessage ?? t("error.unexpectedErrorOccurred");
};

export const mapApiErrorCodes = ({
  t,
  type,
  errors,
}: {
  errors: IncomingFormattedError[];
  t: GetTranslations;
  type: ApiErrorType;
}) => errors.map(error => mapApiErrorCode({ t, type, error }));
